<template>
    <el-config-provider :size="settingsStore.app.elementSize">
        <RouterView
            :style="{
                '--g-main-sidebar-actual-width': mainSidebarActualWidth,
                '--g-sub-sidebar-actual-width': subSidebarActualWidth
            }"
        />
    </el-config-provider>
</template>

<script setup>
import { useSettingsStore } from '@/store/modules/settings';
import { useUserStore } from './store/modules/user';

const settingsStore = useSettingsStore();

const userStrore = useUserStore();
userStrore.refreshTask();

// 侧边栏主导航当前实际宽度
const mainSidebarActualWidth = computed(() => {
    let actualWidth = getComputedStyle(document.documentElement).getPropertyValue('--g-main-sidebar-width');
    actualWidth = parseInt(actualWidth);
    if (['head', 'single'].includes(settingsStore.menu.menuMode)) {
        actualWidth = 0;
    }
    return `${actualWidth}px`;
});

// 侧边栏次导航当前实际宽度
const subSidebarActualWidth = computed(() => {
    let actualWidth = getComputedStyle(document.documentElement).getPropertyValue('--g-sub-sidebar-width');
    actualWidth = parseInt(actualWidth);
    if (settingsStore.menu.subMenuCollapse) {
        actualWidth = 64;
    }
    return `${actualWidth}px`;
});

watch(
    () => settingsStore.mode,
    () => {
        if (settingsStore.mode === 'pc') {
            settingsStore.$patch(state => {
                state.menu.subMenuCollapse = settingsStore.subMenuCollapseLastStatus;
            });
        } else if (settingsStore.mode === 'mobile') {
            settingsStore.$patch(state => {
                state.menu.subMenuCollapse = true;
            });
        }
        document.body.setAttribute('data-mode', settingsStore.mode);
    },
    {
        immediate: true
    }
);

watch(
    () => settingsStore.menu.menuMode,
    () => {
        document.body.setAttribute('data-menu-mode', settingsStore.menu.menuMode);
    },
    {
        immediate: true
    }
);

watch(
    [() => settingsStore.app.enableDynamicTitle, () => settingsStore.title],
    () => {
        if (settingsStore.app.enableDynamicTitle && settingsStore.title) {
            let title = settingsStore.title;
            const txt = window.location.href.indexOf('linglingtong.1cwb.com') > -1 ? '灵灵通' : import.meta.env.VITE_APP_TITLE;
            document.title = `${title} - ${txt}`;
        } else {
            const txt = window.location.href.indexOf('linglingtong.1cwb.com') > -1 ? '灵灵通' : import.meta.env.VITE_APP_TITLE;
            document.title = txt;
        }
    },
    {
        immediate: true
    }
);

onMounted(() => {
    window.onresize = () => {
        settingsStore.setMode(document.documentElement.clientWidth);
    };
    window.onresize();
});
</script>
<style>
.part-title {
    padding-bottom: 20px;
}
.fullw {
    width: 100%;
}
.el-pagination {
    justify-content: flex-end !important;
    padding-top: 16px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
}

/* 火狐 */
input[type='number'] {
    appearance: textfield;
}

/* 底部间距 */
.has-fixed-page {
    margin-bottom: 110px;
}
.el-pagination {
    justify-content: center !important;
}

/* 公共样式 */
.page-main {
    background-color: #fff;
}
.page-main.gap,
.gap {
    padding: 20px;
    margin-bottom: 110px !important;
}
.el-pagination {
    justify-content: center !important;
}
.el-card.is-always-shadow{
  box-shadow: none !important;
  border: none;
}
</style>
