// 园区端
const Layout = () => import('@/layout/index.vue');
export default [
    {
        path: '/tax/enterprise',
        component: Layout,
        name: 't_enterprise',
        meta: {
            title: '企业管理', // 平台  企业
            icon: 'el-icon-office-building',
            auth: ['t_enterprise'],
            defaultOpened: true
        },
        children: [
            {
                path: 'list', // 平台
                name: 't_enterpriseList',
                component: () => import('@/views/enterprise/list.vue'),
                meta: {
                    title: '企业列表',
                    auth: ['t_enterpriseList']
                }
            },
            {
                path: 'contracttpl', // 平台
                name: 't_taxContractTpl',
                component: () => import('@/views/taxsource/contractTpl.vue'),
                meta: {
                    title: '合同模板管理',
                    auth: ['t_taxContractTpl']
                }
            },
            // {
            //     path: 'platcontract/:enterpriseId', // 平台
            //     name: 't_platEnterpriseContract',
            //     component: () => import('@/views/enterprise/contractView.vue'),
            //     meta: {
            //         title: '企业合同',
            //         sidebar: false,
            //         activeMenu: '/tax/enterprise/list'
            //     }
            // },
            {
                path: 'add', // 平台
                name: 't_enterpriseAdd',
                component: () => import('@/views/enterprise/add.vue'),
                meta: {
                    title: '添加企业',
                    sidebar: false,
                    activeMenu: '/tax/enterprise/list'
                }
            },
            {
                path: 'edit/:id', // 平台
                name: 't_enterpriseEdit',
                component: () => import('@/views/enterprise/add.vue'),
                meta: {
                    title: '编辑企业',
                    sidebar: false,
                    activeMenu: '/tax/enterprise/list'
                }
            },
            {
                path: 'platcontract/:enterpriseId', // 平台
                name: 't_platEnterpriseContract',
                component: () => import('@/views/enterprise/contract.vue'),
                meta: {
                    title: '企业合同',
                    sidebar: false,
                    activeMenu: '/tax/enterprise/list'
                }
            },
            {
                path: 'platcontract/:enterpriseId/add', // 平台
                name: 't_enterpriseContractAdd',
                component: () => import('@/views/enterprise/contractAdd.vue'),
                meta: {
                    title: '企业合同',
                    sidebar: false,
                    activeMenu: '/tax/enterprise/list'
                }
            }
        ]
    },
    {
        path: '/tax/approve',
        component: Layout,
        name: 't_approve',
        meta: {
            title: '审批管理', // 平台  企业
            icon: 'el-icon-office-building',
            auth: ['t_approve'],
            defaultOpened: true
        },
        children: [
            {
                path: 'pay',
                name: 't_pay',
                component: () => import('@/views/settlement/batchs.vue'),
                // component: () => import('@/views/enterprise/business/trades.vue'),
                meta: {
                    title: '代付审批',
                    auth: ['t_pay'],
                    subTitle: '请及时处理企业提交的代付申请'
                }
            },
            {
                path: 'withdraw',
                name: 't_withdraw',
                component: () => import('@/views/enterprise/business/withdrawal_approval.vue'),
                meta: {
                    title: '提现审批',
                    auth: ['t_withdraw'],
                    subTitle: '请及时处理企业提交的提现申请'
                }
            },
            {
                path: 'info/:id', //  园区
                name: 'e_settlementdetail2',
                component: () => import('@/views/settlement/detail.vue'),
                meta: {
                    title: '发包批次详情',
                    subTitle: '该批次中所有人员信息',
                    sidebar: false,
                    activeMenu: '/tax/approve/pay'
                }
            }
        ]
    },
    {
        path: '/tax/invoice',
        name: 't_invoice',
        component: Layout,
        meta: {
            title: '发票管理',
            auth: ['t_invoice'],
            icon: 'el-icon-tickets',
            defaultOpened: true
        },
        children: [
            {
                path: 'approval',
                name: 't_approval',
                component: () => import('@/views/enterprise/invoice/applys.vue'),
                meta: {
                    title: '开票审批',
                    auth: ['t_approval'],
                    subTitle: '请及时处理企业提交的开票申请'
                }
            },
            {
                path: 'records',
                name: 't_records',
                component: () => import('@/views/enterprise/invoice/list.vue'),
                meta: {
                    title: '开票记录',
                    auth: ['t_records'],
                    subTitle: '园区下所有开票记录'
                }
            },
            {
                path: 'subjects', // 平台、企业
                name: 't_subjects',
                component: () => import('@/views/enterprise/invoice/subject.vue'),
                meta: {
                    title: '类目管理',
                    auth: ['t_subjects'],
                    subTitle: '编辑园区可开发票类目信息'
                }
            }
        ]
    },
    {
        path: '/tax/workerpool',
        component: Layout,
        name: 't_workerpool',
        meta: {
            title: '用工池管理',
            auth: ['t_workerpool'],
            icon: 'el-icon-message-box',
            subTitle: '',
            defaultOpened: true
        },
        children: [
            {
                path: 'list', // 平台、园区 、 企业
                name: 't_workerpools',
                component: () => import('@/views/workerpool/list.vue'),
                meta: {
                    title: '用工池',
                    auth: ['t_workerpools'],
                    subTitle: '当前企业下所有人员信息列表'
                }
            },
            {
                path: 'worker/:id', // 平台、园区 、 企业
                name: 't_workerInfo',
                component: () => import('@/views/workerpool/workerInfo.vue'),
                meta: {
                    title: '修改用户信息',
                    sidebar: false,
                    activeMenu: '/tax/workerpool/list',
                    subTitle: ''
                }
            }
        ]
    },
    {
        path: '/tax/datasearch',
        name: 't_datasearch',
        component: Layout,
        meta: {
            title: '数据查询',
            auth: ['t_datasearch'],
            icon: 'el-icon-tickets',
            subTitle: '待提供占位占位',
            defaultOpened: true
        },
        children: [
            {
                path: 'record',
                name: 't_paymentrecord',
                component: () => import('@/views/enterprise/data_search/record.vue'),
                meta: {
                    title: '人员代付记录',
                    auth: ['t_paymentrecord'],
                    subTitle: '人员代付记录'
                }
            },
            {
                path: 'logSearch',
                name: 't_logsearch',
                component: () => import('@/views/taxsource/logSearch.vue'),
                meta: {
                    title: '人员代付过程查询',
                    auth: ['t_paymentrecord'],
                    subTitle: '查询人员发放过程'
                }
            }
        ]
    },
    {
        path: '/tax/reconciliation',
        component: Layout,
        name: 't_reconciliation',
        meta: {
            title: '银企对账', // 园区
            icon: 'el-icon-coin',
            auth: ['t_reconciliation'],
            defaultOpened: true
        },
        children: [
            {
                path: 'list',
                name: 't_reconciliationList',
                component: () => import('@/views/reconciliation/list.vue'),
                meta: {
                    title: '余额对账单查询',
                    auth: ['t_reconciliationList'],
                    subTitle: '人员代付记录'
                }
            },
            {
                path: 'detail1', //  详情
                name: 't_reconciliationDetail1',
                component: () => import('@/views/reconciliation/detail1.vue'),
                meta: {
                    title: '余额对账单查询详情',
                    sidebar: false,
                    activeMenu: '/tax/reconciliation'
                }
            },
            {
                path: 'detail2', //  不符详情
                name: 't_reconciliationDetail2',
                component: () => import('@/views/reconciliation/detail2.vue'),
                meta: {
                    title: '余额对账单查询详情',
                    sidebar: false,
                    activeMenu: '/tax/reconciliation'
                }
            }
        ]
    },
    {
        path: '/tax/sys',
        component: Layout,
        name: 't_sys',
        meta: {
            title: '系统管理',
            auth: ['t_sys'],
            icon: 'el-icon-setting',
            defaultOpened: true
        },
        redirect: '/tax/sys/roles',
        children: [
            {
                path: 'users',
                name: 't_sysUsers',
                component: () => import('@/views/sys/users.vue'),
                meta: {
                    title: '账号管理',
                    auth: ['t_sysUsers']
                }
            },
            {
                path: 'roles',
                name: 't_sysRoles',
                component: () => import('@/views/sys/roles.vue'),
                meta: {
                    title: '角色管理',
                    auth: ['t_sysRoles']
                }
            },
            {
                path: 'roles/add',
                name: 't_sysRolesAdd',
                component: () => import('@/views/sys/rolesAdd.vue'),
                meta: {
                    title: '添加角色',
                    sidebar: false,
                    activeMenu: '/tax/sys/roles'
                }
            },
            {
                path: 'roles/edit/:id',
                name: 't_sysRolesEdit',
                component: () => import('@/views/sys/rolesAdd.vue'),
                meta: {
                    title: '编辑角色',
                    sidebar: false,
                    activeMenu: '/tax/sys/roles'
                }
            },

            {
                path: 'users/add',
                name: 't_sysUsersAdd',
                component: () => import('@/views/sys/usersAdd.vue'),
                meta: {
                    title: '添加账号',
                    sidebar: false,
                    activeMenu: '/tax/sys/users'
                }
            },
            {
                path: 'users/edit/:id',
                name: 't_sysUsersEdit',
                component: () => import('@/views/sys/usersAdd.vue'),
                meta: {
                    title: '编辑账号',
                    sidebar: false,
                    activeMenu: '/tax/sys/users'
                }
            },
            {
                path: 'info',
                name: 't_taxInfo',
                component: () => import('@/views/taxsource/detail.vue'),
                meta: {
                    sidebar: false,
                    title: '园区信息'
                }
            }
        ]
    }
];
