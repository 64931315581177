import { defineStore } from 'pinia';
import { piniaStore } from '@/store';
import api from '@/api';
import { loginPwd, loginStartup, refreshtoken, getuserinfo, getpermissionall } from '@/api/api';

import { useRouteStore } from './route';
import { useMenuStore } from './menu';

let token = {};
function initToken() {
    let tokenStr = localStorage.getItem('token');
    if (tokenStr) {
        try {
            let json = JSON.parse(tokenStr);
            if (json.accessToken && json.refreshToken && json.ttl && json.userId) {
                token = json;
            }
        } catch (e) {
            localStorage.removeItem('token');
        }
    }
}
initToken();

export const useUserStore = defineStore(
    // 唯一ID
    'user',
    {
        state: () => ({
            refreshToken: token.refreshToken || '',
            accessToken: token.accessToken || '',
            failure_time: token.ttl || '',
            userId: token.userId || '',
            phone: token.phone,
            permissions: [],
            taskId: null, // 刷新令牌settimeout标识
            // 登录人信息 http://api-docs.tidoedu.com/project/317/interface/api/8484
            user: {}
        }),
        getters: {
            isLogin: state => {
                let retn = false;
                if (state.accessToken) {
                    if (new Date().getTime() < state.failure_time * 1000) {
                        retn = true;
                    }
                }
                return retn;
            }
        },
        actions: {
            login(data) {
                return new Promise((resolve, reject) => {
                    loginPwd(data)
                        .then(res => {
                            res.body.phone = data.phone;
                            res.body.ttl *= 1000;
                            localStorage.setItem('token', JSON.stringify(res.body));
                            this.accessToken = res.body.accessToken;
                            this.refreshToken = res.body.refreshToken;
                            this.failure_time = res.body.ttl;
                            this.userId = res.body.userId;
                            this.phone = res.body.phone;
                            this.refreshTask();
                            resolve(res);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            },
            logout() {
                return new Promise(resolve => {
                    const routeStore = useRouteStore();
                    const menuStore = useMenuStore();
                    localStorage.removeItem('token');
                    this.accessToken = '';
                    this.refreshToken = '';
                    this.failure_time = '';
                    this.userId = '';
                    routeStore.removeRoutes();
                    menuStore.setActived(0);
                    resolve();
                });
            },
            // 获取登录人信息
            getUserData() {
                return new Promise((resolve, reject) => {
                    getuserinfo()
                        .then(res => {
                            let tmpid = sessionStorage.getItem('tmpid');
                            try {
                                let json = (tmpid && JSON.parse(tmpid)) || {};
                                if (!res.body.platformType) {
                                    res.body.taxSourceId = json.taxSourceId;
                                    res.body.taxSourceName = json.taxSourceName;
                                    res.body.enterpriseName = json.enterpriseName;
                                    res.body.enterpriseId = json.enterpriseId;
                                    res.body.channelVendorCode = json.channelVendorCode;
                                    res.body.channelVendorName = json.channelVendorName;
                                } else if (res.body.platformType === 1) {
                                    res.body.enterpriseId = json.enterpriseId;
                                }
                            } catch (e) {}
                            this.user = res.body;
                            resolve(res);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            },
            // 获取我的权限
            getPermissions() {
                return new Promise(resolve => {
                    getpermissionall({ platformType: this.user.platformType, roleId: this.user.roleId }).then(res => {
                        if (res.code === 10000) {
                            let permission = [];
                            if (res.body.enterprisePermissions)
                                permission = permission.concat(
                                    res.body.enterprisePermissions.map(v => v.permissionCode)
                                );
                            if (res.body.platformPermissions)
                                permission = permission.concat(res.body.platformPermissions.map(v => v.permissionCode));
                            if (res.body.taxSourcePermissions)
                                permission = permission.concat(
                                    res.body.taxSourcePermissions.map(v => v.permissionCode)
                                );
                            if (res.body.channelPermissions)
                                permission = permission.concat(res.body.channelPermissions.map(v => v.permissionCode));
                            // let platO =
                            //     'enterprise,enterpriseList,taxsource,taxsources,workerpool,workerpools,sys,sysUsers,sysRoles,permission.browse';
                            // let taxO =
                            //     'enterprise,enterpriseList,taxContractTpl,paymentshe,invoice,invoicingApproval,invoiceList,INVASubject,workerpool,workerpools,sys,sysUsers,sysRoles,permission.browse';
                            // let etxO =
                            //     'invoice,INVApplay,task,tasks,settlement,settlementNew,settlementBatchs,enterprisebusiness,EBAccounts,EBTrades,workerpool,workerpools,sys,sysUsers,sysRoles,permission.browse';
                            // if (!this.user.platformType) {
                            //     permission = permission.concat(platO.split(','));
                            // } else if (this.user.platformType === 1) {
                            //     permission = permission.concat(taxO.split(','));
                            // } else {
                            //     permission = permission.concat(etxO.split(','));
                            // }
                            this.permissions = permission;
                            resolve(permission);
                            //      console.log(permission)
                        }
                    });
                    // 通过 mock 获取权限
                    /*  api.get('member/permission', {
                        baseURL: '/mock/',
                        params: {
                            account: this.phone
                        }
                    }).then(res => {
                        this.permissions = res.body.permissions
                        resolve(res.body.permissions)
                    }) */
                });
            },
            editPassword(data) {
                return new Promise(resolve => {
                    api.post(
                        'member/edit/password',
                        {
                            account: this.account,
                            password: data.password,
                            newpassword: data.newpassword
                        },
                        {
                            baseURL: '/mock/'
                        }
                    ).then(() => {
                        resolve();
                    });
                });
            },
            refreshTask() {
                if (this.taskId) clearTimeout(this.taskId);
                if (!this.failure_time) return;
                let time = new Date().getTime();
                let out = this.failure_time - time - 300000; // 失效前5分钟刷新令牌

                setTimeout(() => {
                    this.logout()
                    // refreshtoken({ refreshToken: this.refreshtoken }).then(res => {
                    //     res.body.ttl *= 1000;
                    //     localStorage.setItem('token', JSON.stringify(res.body));
                    //     this.accessToken = res.body.accessToken;
                    //     this.refreshToken = res.body.refreshToken;
                    //     this.failure_time = res.body.ttl;
                    //     this.refreshToken = res.body.refreshToken;
                    //     loginStartup({ type: 1 }); // 热启动
                    // });
                }, out);
            }
        }
    }
);

export function useUserOutsideStore() {
    return useUserStore(piniaStore);
}
