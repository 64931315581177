import request from './index';
const baseURL = import.meta.env.VITE_APP_API_BASEURL;

// 系统相关 --start

/**
 * 获取登录图形验证码
 */
export const getmuncode = () => {
    return request({
        url: '/financeuser/captcha/getmuncode/v1.0',
        method: 'POST'
    });
};
/**
 * 密码的登录
 */
export const loginPwd = data => {
    return request({
        url: '/financeuser/user/login/pwd/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 获取用户详情
 */
export const getuserinfo = () => {
    return request({
        url: '/financeuser/user/login/getuserinfo/v1.0',
        method: 'POST'
    });
};
/**
 * 修改个人信息
 */
export const editUser = data => {
    return request({
        url: '/financeuser/administrator/editadministrator/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 修改密码
 */
export const editPassword = data => {
    return request({
        url: '/financeuser/administrator/updatepwd/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 管理员列表请求接口
 * {
 * ?enterpriseId,?taxSourceId,page,pageSize
 * }
 */
export const getpageadministrator = data => {
    return request({
        url: '/financeuser/administrator/getpageadministrator/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 修改管理员信息请求接口
 *  http://api-docs.tidoedu.com/project/254/interface/api/7074
 */
export const editadministrator = data => {
    return request({
        url: '/financeuser/administrator/editadministrator/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 创建管理员接口
 *  http://api-docs.tidoedu.com/project/254/interface/api/7068
 */
export const addadministrator = data => {
    return request({
        url: '/financeuser/administrator/addadministrator/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 获取详情
 *  http://api-docs.tidoedu.com/project/254/interface/api/7506
 */
export const administratorInfo = data => {
    return request({
        url: '/financeuser/administrator/getinfo/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 刷新令牌
 */
export const refreshtoken = data => {
    return request({
        url: '/financeuser/user/login/refreshtoken/v1.0',
        method: 'POST',
        headers: {
            authorization: data.refreshtoken
        }
    });
};
/**
 * 登录上报
 * @param Object data:{
 * type: 0-冷启动，1热启动（刷新token）
 * }
 */
export const loginStartup = data => {
    return request({
        url: '/financeuser/user/login/startup/v1.0',
        method: 'POST',
        data
    });
};

/**
 * 获取阿里上传sts凭证
 *
 */
export const getaliosssSts = data => {
    return request({
        url: '/financeuser/sts/cmsget/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 获取角色权限列表
 *  {
 *  ?platformType : 平台信息 0-平台 1-园区 2-企业
 *  ?roleId
 * }
 */
export const getpermission = data => {
    return request({
        url: '/financeuser/role/getpermission/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 获取用户权限(全部平台，登录权限)
 * @returns
 */
export const getpermissionall = data => {
    return request({
        url: '/financeuser/administrator/getpermissionall/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 获取角色列表
 *  {
 *  ?enterpriseId 企业id
 *  ?size,
 *  ?platformType
 *  ?taxSourceId 园区id
 *  page,pageSize
 * }
 */
export const getroles = data => {
    return request({
        url: '/financeuser/role/getpage/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 添加角色
 *  {
 * enterpriseId, c,permissionIds:[{name,permissionCode}],platformType,remark,taxSourceId
 * }
 */
export const roleAdd = data => {
    return request({
        url: '/financeuser/role/add/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 修改角色
 *  {
 * enterpriseId, c,permissionIds:[{name,permissionCode}],platformType,remark,taxSourceId
 * }
 */
export const roleEdit = data => {
    return request({
        url: '/financeuser/role/edit/v1.0',
        method: 'POST',
        data
    });
};
/**
 * 获取角色详情
 *  {
 * roleId
 * }
 */
export const roleInfo = data => {
    return request({
        url: '/financeuser/role/info/v1.0',
        method: 'POST',
        data
    });
};
// 系统相关 --end
// 企业相关 --start
/**
 *  企业管理-获取企业列表
 * {
 * ?name,page,pageSize,?start,?size
 * }
 */
export const getpageenterprise = data => {
    return request({
        url: '/financeuser/enterprise/getpageenterprise/v1.0',
        method: 'POST',
        data
    });
};
/**
 *  企业管理-添加企业
 * http://api-docs.tidoedu.com/project/254/interface/api/7632
 *
 */
export const addenterprise = data => {
    return request({
        url: '/financeuser/enterprise/addenterprise/v1.0',
        method: 'POST',
        data
    });
};
/**
 *  企业管理-修改企业信息
 * http://api-docs.tidoedu.com/project/254/interface/api/7638
 *
 */
export const editenterprise = data => {
    return request({
        url: '/financeuser/enterprise/editenterprise/v1.0',
        method: 'POST',
        data
    });
};
/**
 *  企业管理-获取企业详情
 * http://api-docs.tidoedu.com/project/254/interface/api/7656
 *
 */
export const enterpriseInfo = data => {
    return request({
        url: '/financeuser/enterprise/info/v1.0',
        method: 'POST',
        data
    });
};
/**
 *  企业管理-获取企业已经绑定的园区列表
 * http://api-docs.tidoedu.com/project/254/interface/api/7650
 *
 */
export const getEnterpriseTaxsources = data => {
    return request({
        url: '/financeuser/enterprise/gettaxsources/v1.0',
        method: 'POST',
        data
    });
};
// 企业相关 --end
// 园区相关 --start
/*
 * 获取园区列表(包含最新且可能没关联任何企业)
 */
export const getTaxsourcePage = data => {
    return request({
        url: '/financeuser/taxsource/getpage/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 园区管理-获取园区列表
 */
export const getTaxsources = data => {
    return request({
        url: '/finance/taxsource/pagelist/v1.0',
        method: 'POST',
        data
    });
};

/*
 * 园区管理-创建园区
 */
export const taxsourceAdd = data => {
    return request({
        url: '/financeuser/taxsource/add/v1.0',
        method: 'POST',
        data
    });
};

/*
 * 园区管理-编辑园区
 */
export const taxsourceEdit = data => {
    return request({
        url: '/financeuser/taxsource/edit/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 园区管理-园区详情
 * {taxSourceId：''}
 */
export const taxsourceInfo = data => {
    return request({
        url: '/financeuser/taxsource/info/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 园区管理-获取园区已经绑定的企业列表
 *
 *{page, pageSize, enterpriseId, taxSourceId, ?size}
 */
export const taxsourceEnterprises = data => {
    return request({
        url: '/financeuser/taxsource/getenterprises/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 园区管理-获取园区未经绑定的企业列表
 *
 *{page, pageSize, taxSourceId}
 */
export const getnobandenterprises = data => {
    return request({
        url: '/financeuser/taxsource/getnobandenterprises/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 园区管理-修改园区与企业关系
 */
export const updateenterprises = data => {
    return request({
        url: '/financeuser/taxsource/updateenterprises/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 园区管理-园区下添加企业（附带合同信息）
 * http://api-docs.tidoedu.com/project/299/interface/api/8568
 */
export const taxsourceenterpriseaccount = data => {
    return request({
        url: '/finance/taxsourceenterpriseaccount/add/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 园区管理-获取模板相关信息（含编辑模板配置地址）
 */
export const gettemplateconf = data => {
    return request({
        url: '/financeuser/taxsource/gettemplateconf/v1.0',
        method: 'POST',
        data
    });
};
export const gettemplateconfv2 = data => {
    return request({
        url: '/financeuser/taxsource/gettemplateconf/v2.0',
        method: 'POST',
        data
    });
};
export const setSignPosition = data => {
    return request({
        url: '/financeuser/taxsource/updatesearchKey/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 园区管理-获取上传合同模板url地址
 */
export const getuploadtemplateurl = data => {
    return request({
        url: '/financeuser/taxsource/getuploadtemplateurl/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 园区管理-关联银企直联
 */
export const taxsourceRelationpay = data => {
    return request({
        url: '/financeuser/taxsource/relationpay/v1.0',
        method: 'POST',
        data
    });
};
// 上传合同模板文件（直接上传文件）
export const uploadtemplatefileUrl =  baseURL + '/financeuser/taxsource/uploadtemplatefile/v1.0';
// 园区 --end
// 合同管理 --start
/*
 * 获取合同信息
 *
 */
export const getcontract = data => {
    return request({
        url: '/financeuser/contract/getcontract/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 添加合同信息
 */
export const addcontract = data => {
    return request({
        url: '/financeuser/contract/addcontract/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 修改合同信息
 */
export const editcontract = data => {
    return request({
        url: '/financeuser/contract/editcontract/v1.0',
        method: 'POST',
        data
    });
};
// 合同管理 --end
// 用工池 --start

/*
 * 获取用工池列表
 * http://api-docs.tidoedu.com/project/254/interface/api/7602
 */
export const getpageemployee = data => {
    return request({
        url: '/financeuser/employee/getpageemployee/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池列表 - 提醒员工签约
 * [{employeeId,taxSourceId}]
 */
export const batchremindsign = data => {
    return request({
        url: '/financeuser/employee/batchremindsign/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池列表 - 获取员工银行卡列表
 * {employeeId}
 */
export const getbanklist = data => {
    return request({
        url: '/financeuser/employee/getbanklist/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池列表 - 获取员工详情
 * {employeeId}
 */
export const employeeInfo = data => {
    return request({
        url: '/financeuser/employee/info/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池列表 -修改用户请求（后台修改走这个接口）
 * {employeeId}
 */
export const editemployee = data => {
    return request({
        url: '/financeuser/employee/editemployee/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池列表 - 批量更新员工银行卡
 */
export const batcheditbank = data => {
    return request({
        url: '/financeuser/employee/batcheditbank/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池 - 获取员工下的园区列表（含合同签署信息）
 */
export const getEmployeetaxsources = data => {
    return request({
        url: '/financeuser/employee/gettaxsources/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池 - 获取e签宝签署合同文件信息
 */
export const getETaxsourceFile = data => {
    return request({
        url: '/financeuser/taxsource/getfile/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池 - 确认生成签署文件
 */
export const createtemplatefile = data => {
    return request({
        url: '/financeuser/taxsource/createtemplatefile/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池 - 上传合同模板
 */
export const uploadtemplate = data => {
    return request({
        url: '/financeuser/taxsource/uploadtemplate/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池 - 获取签署文件预览地址
 */
export const previewtemplatefile = data => {
    return request({
        url: '/financeuser/taxsource/previewtemplatefile/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 用工池 - 获取e签宝签署合同文件信息(新版)
 */
export const taxsourceGetflow = data => {
    return request({
        url: '/financeuser/taxsource/getflow/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 获取用工池导出
 * http://api-docs.zaozaovip.com/project/425/interface/api/24583
 */
export const employeeExport = data => {
    return request({
        url: '/financeuser/employee/employee/export/v1.0',
        method: 'POST',
        responseType: 'blob',
        data
    });
};

export const resign = data => {
    return request({
        url: '/financeuser/employee/resign/v1.0',
        method: 'POST',
        data
    });
};

// 用工池 --end

// 控制台 ---start
/*
 * 园区端控制台
 */
export const consoleTaxsource = data => {
    return request({
        url: '/finance/console/taxsource/v1.0',
        method: 'POST',
        data
    });
};

/*
 * 企业端控制台
 */
export const consoleEnterprise = data => {
    return request({
        url: '/finance/console/enterprise/v1.0',
        method: 'POST',
        data
    });
};

/*
 * 平台端控制台
 */
export const getadminoverview = data => {
    return request({
        url: '/financeuser/administrator/getoverview/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 合作意向列表
 */
export const getCooperationList = data => {
    return request({
        url: '/tchp/business/cooperation/list/v1.0',
        method: 'POST',
        data
    });
};
/*
 * 合作意向-是否回访
 */
export const cooperationEdit = data => {
    return request({
        url: '/tchp/business/cooperation/update/v1.0',
        method: 'POST',
        data
    });
};
// 控制台 ---end

// 重庆 - 自然人代开发票---start
/**
 * 获取企业列表
 */
export const getCqEnterpriseList = data => {
    return request({
        url: '/finance/nptax/authorization/get_auth_list/v1.0',
        method: 'POST',
        data
    });
};

/**
 * 获取开发任务列表
 */
export const getInvoiceTaskList = data => {
    return request({
        url: '/finance/nptax/invoice/query_task_by_page/v1.0',
        method: 'POST',
        data
    });
};

/**
 * 获取经办人列表
 */
export const getOperatorList = data => {
    return request({
        url: '/finance/nptax/operator/get_operator_list/v1.0',
        method: 'POST',
        data
    });
};

/**
 * 获取开票地址
 */
export const getInvoiceUrl = data => {
    return request({
        url: '/finance/nptax/invoice/get_invoice_page/v1.0',
        method: 'POST',
        data
    });
};

/**
 * 更新订单状态
 */
export const setOrderState = data => {
    return request({
        url: '/finance/nptax/invoice/set_task_state/v1.0',
        method: 'POST',
        data
    });
};

// 重庆 - 自然人代开发票---end

// 湖南 - 临时税务登录---start
/**
 * 获取企业列表
 */
export const getHnEnterpriseList = data => {
    return request({
        url: '/finance/ldtax/authorization/get_auth_list/v1.0',
        method: 'POST',
        data
    });
};

/**
 * 获取开发任务列表
 */
export const getLdInvoiceTaskList = data => {
    return request({
        url: '/finance/ldtax/invoice/query_task_by_page/v1.0',
        method: 'POST',
        data
    });
};

/**
 * 获取自然人列表
 */
export const getPersonList = data => {
    return request({
        url: '/finance/ldtax/person/query_person_list/v1.0',
        method: 'POST',
        data
    });
};

/**
 * 更新订单状态
 */
export const setLdOrderState = data => {
    return request({
        url: '/finance/ldtax/invoice/set_task_state/v1.0',
        method: 'POST',
        data
    });
};

// 湖南 - 临时税务登录---end

/*
 * 园区管理-发放过程查询
 */
export const getDistributeDetail = data => {
    return request({
        url: '/finance/enterprisesettlement/process/log/v1.0',
        method: 'POST',
        data
    });
};
