// 企业端
const Layout = () => import('@/layout/index.vue');
export default [
    {
        path: '/channel',
        name: 'c_channel',
        component: Layout,
        redirect: '/channel/cooperation/list',
        meta: {
            title: '管理',
            icon: 'el-icon-document',
            auth: ['c_channel'],
            defaultOpened: true
        },
        children: [{
            path: 'cooperation/list',
            name: 'c_cooperationList',
            component: () => import('@/views/channel/cooperation/list.vue'),
            meta: {
                title: '合作园区',
                auth: ['c_cooperationList']
            }
        },
        {
            path: 'custom/list',
            name: 'c_customList',
            component: () => import('@/views/channel/custom/list.vue'),
            meta: {
                title: '客户管理',
                auth: ['c_customList']
            }
        },
        {
            path: 'custom/contract',
            name: 'c_customContract',
            component: () => import('@/views/channel/custom/contract.vue'),
            meta: {
                title: '合同信息',
                sidebar: false,
                activeMenu: '/channel/custom/contract'
            }
        },
        {
            path: 'settlement/list',
            name: 'c_settlementList',
            component: () => import('@/views/channel/settlement/list.vue'),
            meta: {
                title: '结算流水',
                auth: ['c_settlementList'],
            },
        },
        {
            path: 'settlement/detail',
            name: 'c_settlementDetail',
            component: () => import('@/views/channel/settlement/detail.vue'),
            meta: {
                title: '账单明细',
                sidebar: false,
                activeMenu: '/channel/settlement/detail'
            }
        },
        {
            path: 'settlement/flow',
            name: 'c_settlementFlow',
            component: () => import('@/views/channel/settlement/flow.vue'),
            meta: {
                title: '发放流水',
                sidebar: false,
                activeMenu: '/channel/settlement/flow'
            }
        },
        {
            path: 'receipt/list',
            name: 'c_receiptList',
            component: () => import('@/views/channel/receipt/list.vue'),
            meta: {
                title: '结算回单',
                auth: ['c_receiptList'],
            }
        }]
    },
];
