// 企业端
const Layout = () => import('@/layout/index.vue');
export default [
    {
        path: '/CO/tasks',
        name: 'e_task',
        component: Layout,
        meta: {
            title: '任务管理', // 原型暂无
            icon: 'el-icon-document',
            auth: ['e_task'],
            subTitle: '新增发包前请先在此添加任务',
            defaultOpened: true
        },
        children: [
            {
                path: 'list', // 企业
                name: 'e_tasks',
                component: () => import('@/views/task/list.vue'),
                meta: {
                    title: '任务列表',
                    auth: ['e_tasks'],
                    subTitle: '新增发包前请先在此添加任务'
                }
            },
            {
                path: 'cjr', // 企业
                name: 'e_tasks_cjr',
                component: () => import('@/views/task/cjr.vue'),
                meta: {
                    title: '承接人明细',
                    auth: ['e_tasks'],
                    subTitle: '申请该任务的承接人明细',
                    sidebar: false,
                    activeMenu: '/CO/tasks/list'
                }
            }
        ]
    },
    {
        path: '/CO/settlement',
        component: Layout,
        name: 'e_settlement',
        meta: {
            title: '结算管理',
            icon: 'el-icon-coin',
            auth: ['e_settlement'],
            subTitle: '待提供占位占位',
            defaultOpened: true
        },
        children: [
            {
                path: 'export',
                name: 'e_export',
                component: () => import('@/views/settlement/export.vue'),
                meta: {
                    title: '新增发包',
                    auth: ['e_export'],
                    subTitle: '新增代付从这里开始'
                }
            },
            {
                path: 'batchs', //  园区
                name: 'e_batchs',
                component: () => import('@/views/settlement/batchs.vue'),
                meta: {
                    title: '发包批次',
                    auth: ['e_batchs'],
                    subTitle: '已上传的所有代付批次'
                }
            },
            {
                path: 'info', //  园区
                name: 'e_settlementinfo',
                component: () => import('@/views/settlement/info.vue'),
                meta: {
                    title: '结算明细',
                    auth: ['e_settlementinfo'],
                    subTitle: '所有已提交打款申请的明细'
                }
            },
            {
                path: 'fail', //  园区
                name: 'e_settlementfail',
                component: () => import('@/views/settlement/fail.vue'),
                meta: {
                    title: '结算异常查询',
                    auth: ['e_settlementfail'],
                    subTitle: ''
                }
            },
            {
                path: 'billing', //
                name: 'e_settlementbilling',
                component: () => import('@/views/settlement/billing.vue'),
                meta: {
                    title: '月结算查询',
                    auth: ['e_settlementbilling'],
                    subTitle: '月结算查询'
                }
            },
            {
                path: 'info/:id', //  园区
                name: 'e_settlementdetail',
                component: () => import('@/views/settlement/detail.vue'),
                meta: {
                    title: '发包批次详情',
                    subTitle: '该批次下所有人员信息',
                    sidebar: false,
                    activeMenu: '/CO/settlement/batchs'
                }
            },
            {
                path: 'results',
                name: 'e_settlementresults',
                component: () => import('@/views/settlement/results.vue'),
                meta: {
                    title: '服务成果',
                    auth: ['e_settlementresults'],
                    subTitle: '服务成果'
                }
            }
        ]
    },
    {
        path: '/CO/enterprisebusiness',
        component: Layout,
        name: 'e_enterprisebusiness',
        meta: {
            title: '企业账簿', // 园区 、 企业
            auth: ['e_enterprisebusiness'],
            icon: 'el-icon-notebook',
            subTitle: '待提供占位占位',
            defaultOpened: true
        },
        children: [
            {
                path: 'accounts', // 园区 、 企业
                name: 'e_accounts',
                component: () => import('@/views/enterprise/business/accounts.vue'),
                meta: {
                    title: '企业账户',
                    auth: ['e_accounts'],
                    subTitle: ''
                }
            },
            {
                path: 'trades', // 园区
                name: 'e_trades',
                component: () => import('@/views/enterprise/business/trades.vue'),
                meta: {
                    title: '交易明细',
                    auth: ['e_trades'],
                    subTitle: '明细列表包含代付、服务费、充值等信息'
                }
            }
            /*  {
                path: 'bills', // 园区 原型没了
                name: 'bills',
                component: () => import('@/views/enterprise/business/bills.vue'),
                meta: {
                    title: '企业账单'
                }
            }, */
            /*  {
                path: 'transactions', // 企业
                name: 'transactions',
                component: () => import('@/views/enterprise/business/transactions.vue'),
                meta: {
                    title: '交易流水'
                }
            } */
        ]
    },
    {
        path: '/CO/invoice',
        name: 'e_invoice',
        component: Layout,
        meta: {
            title: '发票管理',
            auth: ['e_invoice'],
            icon: 'el-icon-tickets',
            subTitle: '待提供占位占位',
            defaultOpened: true
        },
        children: [
            {
                path: 'applys', // 平台、企业
                name: 'e_applys',
                component: () => import('@/views/enterprise/invoice/applys.vue'),
                meta: {
                    title: '开票查询',
                    auth: ['e_applys'],
                    subTitle: '已提交的开票查询列表'
                }
            },
            {
                path: 'records', // 平台、企业
                name: 'e_invoiceRecords',
                component: () => import('@/views/enterprise/invoice/list.vue'),
                meta: {
                    title: '开票记录',
                    auth: ['e_invoiceRecords'],
                    subTitle: '所有开票记录列表'
                }
            },
            {
                path: 'applys/info/:id', // 平台
                name: 'e_invoiceApplyInfo',
                component: () => import('@/views/enterprise/invoice/details.vue'),
                meta: {
                    title: '开票查询详情',
                    sidebar: false,
                    activeMenu: '/CO/invoice/applys',
                    subTitle: '发票详情信息'
                }
            }
        ]
    },
    {
        path: '/CO/workerpool',
        component: Layout,
        name: 'e_workerpool',
        meta: {
            title: '用工池管理',
            auth: ['e_workerpool'],
            icon: 'el-icon-message-box',
            subTitle: '',
            defaultOpened: true
        },
        children: [
            {
                path: 'list', // 平台、园区 、 企业
                name: 'e_workerpools',
                component: () => import('@/views/workerpool/list.vue'),
                meta: {
                    title: '用工池',
                    auth: ['e_workerpools'],
                    subTitle: '当前企业下所有人员信息列表'
                }
            },
            {
                path: 'worker/:id', // 平台、园区 、 企业
                name: 'e_workerInfo',
                component: () => import('@/views/workerpool/workerInfo.vue'),
                meta: {
                    title: '修改用户信息',
                    sidebar: false,
                    activeMenu: '/CO/workerpool/list',
                    subTitle: ''
                }
            }
        ]
    },
    {
        path: '/CO/sys',
        component: Layout,
        name: 'e_sys',
        meta: {
            title: '系统管理',
            auth: ['e_sys'],
            icon: 'el-icon-setting',
            defaultOpened: true
        },
        redirect: '/CO/sys/roles',
        children: [
            {
                path: 'users',
                name: 'e_sysUsers',
                component: () => import('@/views/sys/users.vue'),
                meta: {
                    title: '账号管理',
                    auth: ['e_sysUsers']
                }
            },
            {
                path: 'roles',
                name: 'e_sysRoles',
                component: () => import('@/views/sys/roles.vue'),
                meta: {
                    title: '角色管理',
                    auth: ['e_sysRoles']
                }
            },
            {
                path: 'roles/add',
                name: 'e_sysRolesAdd',
                component: () => import('@/views/sys/rolesAdd.vue'),
                meta: {
                    title: '添加角色',
                    sidebar: false,
                    activeMenu: '/CO/sys/roles'
                }
            },
            {
                path: 'roles/edit/:id',
                name: 'e_sysRolesEdit',
                component: () => import('@/views/sys/rolesAdd.vue'),
                meta: {
                    title: '编辑角色',
                    sidebar: false,
                    activeMenu: '/CO/sys/roles'
                }
            },

            {
                path: 'users/add',
                name: 'e_sysUsersAdd',
                component: () => import('@/views/sys/usersAdd.vue'),
                meta: {
                    title: '添加账号',
                    sidebar: false,
                    activeMenu: '/CO/sys/users'
                }
            },
            {
                path: 'users/edit/:id',
                name: 'e_sysUsersEdit',
                component: () => import('@/views/sys/usersAdd.vue'),
                meta: {
                    title: '编辑账号',
                    sidebar: false,
                    activeMenu: '/CO/sys/users'
                }
            },
            {
                path: 'info',
                name: 'e_enterpriseInfo',
                component: () => import('@/views/enterprise/contracts.vue'),
                meta: {
                    title: '企业信息',
                    sidebar: false
                }
            }
        ]
    }
];
