import dayjs from 'dayjs';
window.dayjs = dayjs;
import { SettlementStatusList, InvoiceStatusList, TradeStatusList, WithdrawalStatusList } from './const';
function getValue(list, v) {
    return list.find(item => item.value == v)?.label;
}
export const FORMAT = {
    yuan(num) {
        return isNaN(num / 100) ? '0元' : num / 100 + '元';
    },
    dateToStr(time, template = 'YYYYMMDDHHmmss') {
        return dayjs(time).format(template);
    },
    dateToStr1(time, template = 'YYYYMMDD235959') {
        return dayjs(time).format(template);
    },
    dateToStr2(time) {
        return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    },
    strDateFormat(str, templateStr = 'YYYY-MM-DD HH:mm:ss') {
        if (!str) return str;
        return dayjs(str.toString(), 'YYYYMMDDHHmmss').format(templateStr);
    },
    strToDate(str) {
        return dayjs(str?.toString(), 'YYYYMMDDHHmmss').toDate();
    },
    status: {
        settlement: getValue.bind(null, SettlementStatusList),
        trade: getValue.bind(null, TradeStatusList),
        withdrawal: getValue.bind(null, WithdrawalStatusList),
        invoice: getValue.bind(null, InvoiceStatusList)
    }
};
export default FORMAT;
