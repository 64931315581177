<route>
{
    name: 'dashboard',
    meta: {
        title: "控制台"
        }
}
</route>

<template>
    <div>
        <page-header :title="signTitle">
            <template #content>
                <div>
                    <div style="margin-bottom: 5px;">{{ signTitle }}一站式解决企业用户、个人用户灵活用工服务，高效、安全、更省钱</div>
                </div>
            </template>
        </page-header>

        <el-row v-if="!userStore.user.platformType" :gutter="12" style="margin: 0 20px;">
            <el-col :lg="8">
                <page-main @click="goPage('p_enterpriseList')">
                    <div class="item">
                        <el-icon :size="45">
                            <School />
                        </el-icon>
                        <div class="part">
                            <div class="name">签约企业</div>
                            <div>
                                <span class="num">{{ plat.enterprise.num }}</span><span>{{ plat.enterprise.ex||'' }}
                                    家</span>
                            </div>
                        </div>
                    </div>
                </page-main>
            </el-col>
            <el-col :lg="8">
                <page-main @click="goPage('p_taxsources')">
                    <div class="item">
                        <el-icon :size="45">
                            <office-building />
                        </el-icon>
                        <div class="part">
                            <div class="name">拥有园区</div>
                            <div><span class="num">{{ plat.taxs.num }}</span><span>{{ plat.taxs.ex||'' }} 家</span></div>
                        </div>
                    </div>
                </page-main>
            </el-col>
        </el-row>

        <el-row v-if="userStore.user.platformType===1" :gutter="20" style="margin: 0 20px;">
            <el-col :lg="8">
                <page-main @click="goPage('t_pay')">
                    <div class="item">
                        <el-icon :size="45">
                            <stamp />
                        </el-icon>
                        <div class="part">
                            <div class="name">代付待审批</div>
                            <div>
                                <span
                                    class="num"
                                >{{ tax.settlementCount.num }}</span><span>{{ tax.settlementCount.ex||'' }}
                                    条</span>
                            </div>
                        </div>
                    </div>
                </page-main>
            </el-col>
            <el-col :lg="8">
                <page-main @click="goPage('t_withdraw')">
                    <div class="item">
                        <el-icon :size="45">
                            <money />
                        </el-icon>
                        <div class="part">
                            <div class="name">提现待审批</div>
                            <div>
                                <span
                                    class="num"
                                >{{ tax.withdrawalCount.num }}</span><span>{{ tax.withdrawalCount.ex||'' }}
                                    条</span>
                            </div>
                        </div>
                    </div>
                </page-main>
            </el-col>
            <el-col :lg="8">
                <page-main @click="goPage('t_approval')">
                    <div class="item">
                        <el-icon :size="45">
                            <list />
                        </el-icon>
                        <div class="part">
                            <div class="name">发票待审批</div>
                            <div>
                                <span class="num">{{ tax.invoiceCount.num }}</span><span>{{ tax.invoiceCount.ex||'' }}
                                    条</span>
                            </div>
                        </div>
                    </div>
                </page-main>
            </el-col>
        </el-row>

        <el-row v-if="userStore.user.platformType===2" style="margin: 0 20px;">
            <el-col :lg="8">
                <page-main @click="goPage('e_accounts')">
                    <div class="item">
                        <el-icon :size="45">
                            <coin />
                        </el-icon>
                        <div class="part">
                            <div class="name">可用余额</div>
                            <div><span class="num">{{ enterp.accountBalance.num }}</span><span>元</span></div>
                        </div>
                    </div>
                </page-main>
            </el-col>
            <el-col :lg="8">
                <page-main @click="goPage('e_trades')">
                    <div class="item">
                        <el-icon :size="45">
                            <postcard />
                        </el-icon>
                        <div class="part">
                            <div class="name">待开发票</div>
                            <div>
                                <span
                                    class="num"
                                >{{ enterp.noInvoiceCount.num }}</span><span>{{ enterp.noInvoiceCount.ex||'' }}
                                    条</span>
                            </div>
                        </div>
                    </div>
                </page-main>
            </el-col>
            <el-col :lg="8">
                <page-main @click="goPage('e_workerpools')">
                    <div class="item">
                        <el-icon :size="45">
                            <Edit />
                        </el-icon>
                        <div class="part">
                            <div class="name">未签约用户</div>
                            <div>
                                <span
                                    class="num"
                                >{{ enterp.noSignEmpSize.num }}</span><span>{{ enterp.noSignEmpSize.ex||'' }}
                                    条</span>
                            </div>
                        </div>
                    </div>
                </page-main>
            </el-col>
        </el-row>

        <el-row style="margin: 0 20px;">
            <el-col
                v-if="userStore.user.platformType===2"
                :lg="16"
            >
                <page-main>
                    <div class="item-title">邀请员工注册签约</div>
                    <div class="item-using">
                        <div class="item-using-content">
                            <div id="minicode" class="ewm" />
                            <div>复制小程序二维码分享给员工，引导其通过小程序，完成注册、签约</div>
                            <div class="copy-btn" @click="copyCode('mini')">复制小程序码</div>
                        </div>
                        <!-- <div v-if="!showH5Code.includes('we9h24vi5bdtf008b19g')" class="item-using-content">
                            <div id="minicode" class="ewm" />
                            <div>复制小程序二维码分享给员工，引导其通过小程序，完成注册、签约</div>
                            <div class="copy-btn" @click="copyCode('mini')">复制小程序码</div>
                        </div>
                        <div v-if="showH5Code.includes('we9h24vi5bdtf008b19g')" class="item-using-content">
                            <div id="h5code" class="ewm" />
                            <div>复制网页二维码分享给员工，引导其通过链接，完成注册、税局认证</div>
                            <div class="copy-btn" @click="copyCode('h5')">复制H5二维码</div>
                        </div>
                        <div v-if="env !== 'production'" class="item-using-content">
                            <div id="h5code" class="ewm" />
                            <div>复制网页二维码分享给员工，引导其通过链接，完成注册、税局认证</div>
                            <div class="copy-btn" @click="copyCode('h5')">复制H5二维码</div>
                        </div> -->
                    </div>
                </page-main>
            </el-col>
            <el-col :lg="8" :class="[userStore.user.platformType !== 2 ? 'p-l-r' : '']">
                <page-main>
                    <div class="item-title">使用帮助</div>
                    <div class="item-using">
                        <div class="item-using-content">
                            <div class="file">
                                <img src="../assets/images/file.png" alt="">
                            </div>
                            <div v-if="userStore.user.platformType===2">易汇云灵工平台操作手册——企业方.pptx</div>
                            <div class="copy-btn" @click="onDownLoad">下载文档</div>
                        </div>
                    </div>
                </page-main>
            </el-col>
        </el-row>
    </div>
</template>
<script setup>
import {
    ElMessage
} from 'element-plus';
import {
    useUserStore
} from '@/store/modules/user';
import {
    School,
    OfficeBuilding,
    List,
    Stamp,
    Money,
    Coin,
    Postcard,
    Edit
} from '@element-plus/icons-vue';
import {
    consoleTaxsource,
    consoleEnterprise,
    getadminoverview,
    getEnterpriseTaxsources
} from '@/api/api';
import {
    moneyFormat
} from '@/util/util';
import QRCode from 'qrcodejs2-fix';
const userStore = useUserStore();
const router = useRouter();
const {
    proxy
} = getCurrentInstance();
const env = process.env.NODE_ENV;
console.log(env);
const signTitle = ref('');
onMounted(() => {
    signTitle.value = localStorage.getItem('channelName');
});

// const showH5Code = ['m84x81cvjf9tvkuecjnw', 'y93lksmjvkrcjki4ie8t', 'ym88m70y7qfqx2f7yzsf', '8u1e77wm1knxhtol76pj', '78buoj5vbb0jxx7t1h8s'];
const showH5Code = ref([]);

function goPage(auth) {
    if (proxy.$auth(auth)) {
        router.push({
            name: auth
        });

    }
}

function delNum(num) {
    if (num < 10000) {
        return {
            num: num.toString().replace(/\B(?=(?:\d{3})+$)/g, ',')
        };
    }
    let str = parseFloat(num / 10000).toFixed(1);
    let n = str.split('.')[0].replace(/\B(?=(?:\d{3})+$)/g, ',');
    let ex = str.split('.')[1];
    if (ex !== 0) {
        n += '.' + ex;
    }

    return {
        num: n,
        ex: 'w+'
    };
}
const plat = ref({
    taxs: {
        num: ''
    },
    enterprise: {
        num: ""
    }
});

function getAdmin() {
    getadminoverview({
        userId: userStore.user.userId
    }).then(res => {
        plat.value.enterprise = delNum(res.body.enterpriseSize);
        plat.value.taxs = delNum(res.body.taxSourceSize);
    });
}

const tax = ref({
    settlementCount: {
        num: ""
    }, // 	代付待审批数量
    withdrawalCount: {
        num: ""
    }, // 提现待审批数量
    invoiceCount: {
        num: ""
    } // 发票待审批数量
});

function getConsoleTax() {
    consoleTaxsource().then(res => {
        tax.value.settlementCount = delNum(res.body.settlementCount);
        tax.value.withdrawalCount = delNum(res.body.withdrawalCount);
        tax.value.invoiceCount = delNum(res.body.invoiceCount);
    });
}
const enterp = ref({
    accountBalance: {
        num: ""
    }, // 账户总共可用余额
    noInvoiceCount: {
        num: ''
    }, // 待开发票数量
    noSignEmpSize: {
        num: ""
    } // 未签约的用户数量
});

function getConsoleEnterp() {
    consoleEnterprise().then(res => {
        enterp.value.accountBalance = {
            num: moneyFormat(res.body.accountBalance)
        };
        enterp.value.noInvoiceCount = delNum(res.body.noInvoiceCount);
        enterp.value.noSignEmpSize = delNum(res.body.noSignEmpSize);
    });
}

function base64ToBlob(url) {
    let arr = url.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
        type: mime
    });
}

function getH5code() {
    let domain = window.location.origin === 'https://tchp.1cwb.com' ?
        'https://tchp.1cwb.com/h5/login?id=' :
        'http://tchp-alpha.1cwb.com/h5/login?id=';
    let container = document.getElementById('h5code');
    if (container !== '') {
        container.innerHTML = '';
    }
    let qrcode = new QRCode(container, {
        text: domain + userStore.user.enterpriseId,
        width: 100,
        height: 100,
        colorDark: 'black',
        colorLight: 'white',
        correctLevel: QRCode.CorrectLevel.L
    });
    console.log(qrcode, userStore);
}

function getMinicode() {
    let domain = window.location.origin === 'https://tchp.1cwb.com' ?
        'https://tchp.1cwb.com/r?id=' :
        'http://tchp-alpha.1cwb.com/r?id=';
    let container = document.getElementById('minicode');
    if (container !== '') {
        container.innerHTML = '';
    }
    let qrcode = new QRCode(container, {
        text: domain + userStore.user.enterpriseId,
        width: 100,
        height: 100,
        colorDark: 'black',
        colorLight: 'white',
        correctLevel: QRCode.CorrectLevel.L
    });
    console.log('-----', userStore, qrcode);
}

function copyCode(type) {
    let container = '';
    if (type === 'h5') {
        container = document.getElementById('h5code').childNodes[1];
    } else {
        container = document.getElementById('minicode').childNodes[1];
    }
    let myBlob = base64ToBlob(container.getAttribute('src'));
    navigator.clipboard.write([new window.ClipboardItem({
        [myBlob.type]: myBlob
    })]);
    ElMessage.success('已复制');
}

function onDownLoad() {
    if (userStore.user.platformType === 0) {
        window.open('src/assets/file/易汇云灵工平台操作手册——平台方.pptx');
    }
    if (userStore.user.platformType === 1) {
        window.open('src/assets/file/易汇云灵工平台操作手册——园区方.pptx');
    }
    if (userStore.user.platformType === 2) {
        window.open('src/assets/file/易汇云灵工平台操作手册——企业方.pptx');
    }
}

onMounted(() => {
    if (!userStore.user.platformType) {
        getAdmin();
    } else if (userStore.user.platformType === 1) {
        getConsoleTax();
    } else if (userStore.user.platformType === 2) {
        getConsoleEnterp();
        getEnterpriseTaxsources({ enterpriseId: userStore.user.enterpriseId }).then(res => {
            showH5Code.value = res.body.map(item => (item.taxSourceId));
            setTimeout(() => {
                // if (showH5Code.includes(userStore.user.enterpriseId)) {
                // if (showH5Code.value.includes('we9h24vi5bdtf008b19g')) {
                //     getH5code();
                // }
                // if (env !== 'production') {
                //     getH5code();
                // }
                getMinicode();
            }, 100);
        });
    }
});
</script>
<style lang="scss" scoped>
    .text-emphasis {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        // -webkit-text-emphasis-style: "❤";
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0;

        .icon {
            font-size: 30px;
        }

        .part {
            padding-left: 10px;
        }

        .name {
            padding-bottom: 10px;
        }

        .num {
            font-size: 24px;
            font-weight: 500;
        }
    }

    .item-title {
        padding: 20px 0 0 15px;
    }

    .item-using {
        padding: 20px 50px;
        height: 247px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .item-using-content {
        padding: 0 50px;
        text-align: center;
        font-size: 14px;
        color: #666;
        .ewm {
            margin: 0 auto 15px;
            width: 100px;
            height: 100px;
            text-align: center;
        }

        .copy-btn {
            cursor: pointer;
            margin: 15px auto 0;
            background-color: #38A28B;
            color: #FFF;
            height: 40px;
            width: 140px;
            line-height: 40px;
            text-align: center;
            border-radius: 20px;
        }

        .file {
            width: 100px;
            height: 100px;
            margin: 0 auto 15px;

            >img {
                width: 75px;
                height: 94px;
            }
        }
    }

    .page-main {
        margin: 10px;
    }

    .p-l-r {
        padding: 0 10px;
    }
</style>
