export default [
    {
        url: '/mock/member/login',
        method: 'post',
        response: option => {
            return {
                error: '',
                status: 1,
                data: {
                    account: option.body.account,
                    token: '@string',
                    failure_time: Math.ceil(new Date().getTime() / 1000) + 24 * 60 * 60
                }
            };
        }
    },
    {
        url: '/mock/member/permission',
        method: 'get',
        response: option => {
            let permissions = [];
            if (option.query.account == 'admin') {
                permissions = [
                    'permission.browse',
                    'permission.create',
                    'permission.edit',
                    'permission.remove',

                    'enerprise',
                    'enerpriseAdd',
                    'enerpriseEdit',
                    'platcontract',
                    'contractList',
                    'EtaxsourceList'
                ];
            } else if (option.query.account == 'test') {
                permissions = ['permission.browse'];
            } else if (option.query.account === '13100000000') {
                // 平台
                permissions = [
                    'permission.browse',
                    'enterprise',
                    'enterpriseList',
                    'enterpriseAdd',
                    'enterpriseEdit',
                    'platcontract',
                    'taxsource',
                    'taxsourcesEdit',
                    'taxsourcesAdd',
                    'tenerprise',
                    'invoice',
                    'invoiceApplys',
                    'invoiceApplyExpressMsg',
                    'invoiceApplyInfo',
                    'invoiceApplyOdLink',
                    'invoiceApplyed',
                    'invoiceApplyBatchLink',
                    'invoiceList',
                    'workerpool',
                    'workerpools',
                    'sys',
                    'sysRoles',
                    'sysRolesAdd',
                    'sysRolesEdit',
                    'sysUsers',
                    'sysUsersAdd',
                    'sysUsersEdit'
                ];
            } else if (option.query.account === '13200000000') {
                // 园区
                permissions = [
                    'permission.browse',
                    'settlement',
                    'settlementInfo',
                    'settlementBatchs',
                    'enterprisebusiness',
                    'EBAccounts',
                    'EBTrades',
                    'taxReceipt',
                    'TRInvoiceApplay',
                    'workerpool',
                    'workerpools',
                    'sys',
                    'sysRoles',
                    'sysRolesAdd',
                    'sysRolesEdit',
                    'sysUsers',
                    'sysUsersAdd',
                    'sysUsersEdit'
                ];
            } else if (option.query.account === '13300000000') {
                // 企业
                permissions = [
                    'permission.browse',
                    'enterprisebusiness',
                    'EBAccounts',
                    'EBTransactions',
                    'invoice',
                    'invoiceApplys',
                    'invoiceApplyExpressMsg',
                    'invoiceApplys',
                    'workerpool',
                    'workerpools',
                    'settlement',
                    'settlementInfo',
                    'settlementBatchs',
                    'enterprise',
                    'contractList',
                    'EtaxsourceList',
                    'sys',
                    'sysRoles',
                    'sysRolesAdd',
                    'sysRolesEdit',
                    'sysUsers',
                    'sysUsersAdd',
                    'sysUsersEdit'
                ];
            }
            return {
                message: '',
                code: 10000,
                body: {
                    permissions
                }
            };
        }
    },
    {
        url: '/mock/member/edit/password',
        method: 'post',
        response: {
            error: '',
            status: 1,
            data: {
                isSuccess: true
            }
        }
    }
];
