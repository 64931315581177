/*
* 金额格式化
* @params number val (分)
* @return string  e.g 12,345.67
*/
export const moneyFormat = val => {
    var str = (val / 100).toFixed(2) + '';
    var intSum = str.substring(0, str.indexOf('.')).replace(/\B(?=(?:\d{3})+$)/g, ',');// 取到整数部分
    var dot = str.substring(str.length, str.indexOf('.'));// 取到小数部分搜索
    var ret = intSum + dot;
    return ret;
};

export const findOne = (arr, val, empty = null, key = 'id') => {
    for (let i = 0;i < arr.length;i++) {
        if (arr[i][key] === val) {
            return arr[i];
        }
    }
    return empty;
};

// 获取文件地址
export const getFileUrl = file => {
    return new Promise(rs => {
        let reader = new FileReader();
        reader.onload = function(e) {
            rs(e.target.result);
        };
        reader.onerror = function(e) {
            console.error('errr', e);
        };
        reader.readAsDataURL(file);
    });
};

