import MultilevelMenuExample from './modules/multilevel.menu.example'

const menu = [
    {
        meta: {
            title: '演示',
            icon: 'sidebar-default'
        },
        children: [
            MultilevelMenuExample
        ]
    }
]

export default menu
