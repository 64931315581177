import axios from 'axios';
// import qs from 'qs'
import router from '@/router/index';
import { ElMessage } from 'element-plus';
import { useUserOutsideStore } from '@/store/modules/user';

// eslint-disable-next-line no-unused-vars
const toLogin = () => {
    const userOutsideStore = useUserOutsideStore();
    userOutsideStore.logout().then(() => {
        router.push({
            name: 'login',
            query: {
                redirect: router.currentRoute.value.path !== '/login' ? router.currentRoute.value.fullPath : undefined
            }
        });
    });
};

const api = axios.create({
    // baseURL: , // (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true' ? '/backapi' : import.meta.env.VITE_APP_API_BASEURL),
    timeout: 60000,
    responseType: 'json'
});

api.interceptors.request.use(request => {
    const userOutsideStore = useUserOutsideStore();
    if (request.url[0] === '/') {
        request.baseURL = import.meta.env.VITE_APP_API_BASEURL;
    }
    /**
     * 全局拦截请求发送前提交的参数
     * 以下代码为示例，在请求头里带上 token 信息
     */
    if (userOutsideStore.isLogin && !request.headers['authorization']) {
        request.headers['authorization'] = userOutsideStore.accessToken;
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (request.method === 'post') {
        // request.data = qs.stringify(request.data, {
        //     arrayFormat: 'brackets'
        // })
    }
    return request;
});

api.interceptors.response.use(
    response => {
        if (response.data instanceof Blob) {
            return response;
        }
        /**
         * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
         * 假设返回数据格式为：{ status: 1, error: '', data: '' }
         * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
         * 请求出错时 error 会返回错误信息
         */
        if (response.status !== 200) {
            ElMessage.error('请检查网络');
            return Promise.reject({ code: 0, message: '网络异常' });
        }
        if (response.config.baseURL !== import.meta.env.VITE_APP_API_BASEURL) {
            return response.data;
        }
        if (response.config.responseType === 'json') {
            let data = response.data;
            if (data.code === 10000) {
                return data;
            }
            if (data.code !== 20004) {
                ElMessage.error(data.message);
            }
            // token过期            || refresh token 过期  || 登录被踢出           ||  token无效
            if (data.code === 10011) {
                toLogin();
            }
            return Promise.reject(data);
        }
        return response;
        /*  if (response.data.status === 1) {
            if (response.data.error === '') {
                // 请求成功并且没有报错
                return Promise.resolve(response.data)
            } else {
                // 这里做错误提示
                // ElMessage.error(options)
                return Promise.reject(response.data)
            }
        } else {
            toLogin()
        } */
    },
    error => {
        let message = error.message;
        if (message == 'Network Error') {
            message = '后端网络故障';
        } else if (message.includes('timeout')) {
            message = '接口请求超时';
        } else if (message.includes('Request failed with status code')) {
            message = '接口' + message.substr(message.length - 3) + '异常';
        }
        ElMessage({
            message,
            type: 'error'
        });
        return Promise.reject(error);
    }
);

export default api;
