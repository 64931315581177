// 发票状态列表
export const InvoiceStatusList = [
    {
        value: 1,
        label: '待审核'
    },
    {
        value: 2,
        label: '已拒绝'
    },
    {
        value: 3,
        label: '开票中'
    },
    {
        value: 4,
        label: '已开票'
    },
    {
        value: 5,
        label: '已邮寄'
    }
];
// 1-待提交（已生成批次），2-待提交（已提交），3-审核失败（被拒绝），4-出款中（待支付），6-已完成（结算成功），7-已关闭
export const SettlementStatusList = [
    {
        value: 1,
        label: '待提交'
    },
    {
        value: 2,
        label: '待审核'
    },
    {
        value: 3,
        label: '审核失败'
    },
    {
        value: 4,
        label: '出款中'
    },
    {
        value: 5,
        label: '部分成功'
    },
    {
        value: 6,
        label: '已完成'
    },
    {
        value: 7,
        label: '已关闭'
    }
];

// 交易类型：1-充值，2-代付，3-服务费

export const TradeStatusList = [
    {
        value: 1,
        label: '充值'
    },
    {
        value: 2,
        label: '代付'
    },
    {
        value: 3,
        label: '服务费'
    }
];

// 提现状态：2-待审核，3-审核失败（被拒绝），4-提现中，5-提现成功，6-提现失败

export const WithdrawalStatusList = [
    {
        value: 2,
        label: '待审核'
    },
    {
        value: 3,
        label: '审核失败'
    },
    {
        value: 4,
        label: '提现中'
    },
    {
        value: 5,
        label: '提现成功'
    },
    {
        value: 6,
        label: '提现失败'
    }
];
