// 平台端
const Layout = () => import('@/layout/index.vue');
export default [
    {
        path: '/plat/enterprise',
        component: Layout,
        name: 'p_enterprise',
        redirect: '/plat/enterprise/list',
        meta: {
            title: '企业管理', // 平台  企业
            icon: 'el-icon-school',
            auth: ['p_enterprise'],
            defaultOpened: true
        },
        children: [
            {
                path: 'list', // 平台
                name: 'p_enterpriseList',
                component: () => import('@/views/enterprise/list.vue'),
                meta: {
                    title: '企业列表',
                    auth: ['p_enterpriseList']
                }
            },
            {
                path: 'add', // 平台
                name: 'p_enterpriseAdd',
                component: () => import('@/views/enterprise/add.vue'),
                meta: {
                    title: '添加企业',
                    sidebar: false,
                    activeMenu: '/plat/enterprise/list'
                }
            },
            {
                path: 'edit/:id', // 平台
                name: 'p_enterpriseEdit',
                component: () => import('@/views/enterprise/add.vue'),
                meta: {
                    title: '编辑企业',
                    sidebar: false,
                    activeMenu: '/plat/enterprise/list'
                }
            },
            {
                path: 'platcontract/:enterpriseId', // 平台
                name: 'p_platEnterpriseContract',
                component: () => import('@/views/enterprise/contract.vue'),
                meta: {
                    title: '企业合同',
                    sidebar: false,
                    activeMenu: '/plat/enterprise/list'
                }
            }
        ]
    },
    {
        path: '/plat/taxsource',
        component: Layout,
        name: 'p_taxsource',
        meta: {
            title: '园区管理',
            icon: 'el-icon-office-building',
            auth: ['p_taxsource'],
            defaultOpened: true
        },
        children: [
            {
                path: 'list', // 平台
                name: 'p_taxsources',
                component: () => import('@/views/taxsource/list.vue'),
                meta: {
                    title: '园区列表',
                    auth: ['p_taxsources']
                }
            },
            {
                path: 'edit/:id', // 平台
                name: 'p_taxsourcesEdit',
                component: () => import('@/views/taxsource/edit.vue'),
                meta: {
                    title: '园区编辑',
                    sidebar: false,
                    activeMenu: '/plat/taxsource/list'
                }
            },
            {
                path: 'add', // 平台
                name: 'p_taxsourcesAdd',
                component: () => import('@/views/taxsource/edit.vue'),
                meta: {
                    title: '园区添加',
                    sidebar: false,
                    activeMenu: '/plat/taxsource/list'
                }
            },
            {
                path: 'enerprise/:taxId', // 平台
                name: 'p_tenerprise',
                component: () => import('@/views/taxsource/enerprises.vue'),
                meta: {
                    title: '企业管理',
                    sidebar: false,
                    activeMenu: '/plat/taxsource/list'
                }
            },
            {
                path: 'channel/:taxId', // 平台
                name: 'p_channel',
                component: () => import('@/views/taxsource/channel.vue'),
                meta: {
                    title: '代发通道管理',
                    sidebar: false,
                    activeMenu: '/plat/taxsource/channel'
                }
            }
        ]
    },
    {
        path: '/plat/channel',
        component: Layout,
        name: 'p_channel',
        meta: {
            title: '渠道管理',
            auth: ['p_channel'],
            icon: 'el-icon-setting',
            defaultOpened: true
        },
        redirect: '/plat/channel/list',
        children: [
            {
                path: 'list',
                name: 'p_channelList',
                component: () => import('@/views/channel/platform/channelList.vue'),
                meta: {
                    title: '渠道列表',
                    auth: ['p_channelList']
                }
            },
            {
                path: 'add',
                name: 'p_channelAdd',
                component: () => import('@/views/channel/platform/channelEdit.vue'),
                meta: {
                    title: '渠道添加',
                    sidebar: false,
                    activeMenu: '/plat/channel/add'
                }
            },
            {
                path: 'edit/:code',
                name: 'p_channelEdit',
                component: () => import('@/views/channel/platform/channelEdit.vue'),
                meta: {
                    title: '渠道编辑',
                    sidebar: false,
                    activeMenu: '/plat/channel/add',
                }
            },
            {
                path: 'settlement',
                name: 'p_settlementList',
                component: () => import('@/views/channel/platform/settlementList.vue'),
                meta: {
                    title: '结算管理',
                    auth: ['p_settlementList']
                }
            }
        ]
    },
    {
        path: '/plat/cooperation',
        component: Layout,
        name: 'p_cooperation',
        meta: {
            title: '合作意向',
            auth: ['p_cooperation'],
            icon: 'el-icon-setting',
            defaultOpened: true
        },
        redirect: '/plat/cooperation/apply',
        children: [
            {
                path: 'apply',
                name: 'p_apply',
                component: () => import('@/views/cooperation/apply.vue'),
                meta: {
                    title: '合作申请',
                    auth: ['p_apply']
                }
            }
        ]
    },
    {
        path: '/taxOther/toManage',
        component: Layout,
        name: 'p_taxOther',
        meta: {
            title: '税务管理',
            auth: ['p_taxOther'],
            icon: 'el-icon-monitor',
            defaultOpened: true
        },
        redirect: '/taxOther/toManage/chongQing',
        children: [
            {
                path: 'chongQing',
                name: 'p_chongQing',
                component: () => import('@/views/taxOther/chongQing/index.vue'),
                meta: {
                    title: '自然人代开发票管理',
                    auth: ['p_chongQing']
                }
            },
            {
                path: 'detail',
                name: 'p_chongQing_enterprise_detail',
                component: () => import('@/views/taxOther/chongQing/detail.vue'),
                meta: {
                    title: '详情',
                    sidebar: false,
                    activeMenu: '/taxOther/toManage/chongQing'
                }
            },
            {
                path: 'huNan',
                name: 'p_huNan',
                component: () => import('@/views/taxOther/huNan/index.vue'),
                meta: {
                    title: '临时税务登记管理',
                    auth: ['p_huNan']
                }
            },
            {
                path: 'hnDetail',
                name: 'p_huNan_enterprise_detail',
                component: () => import('@/views/taxOther/huNan/detail.vue'),
                meta: {
                    title: '详情',
                    sidebar: false,
                    activeMenu: '/taxOther/toManage/huNan'
                }
            }
        ]
    },
    {
        path: '/plat/sys',
        component: Layout,
        name: 'p_sys',
        meta: {
            title: '系统管理',
            auth: ['p_sys'],
            icon: 'el-icon-setting',
            defaultOpened: true
        },
        redirect: '/plat/sys/roles',
        children: [
            {
                path: 'users',
                name: 'p_sysUsers',
                component: () => import('@/views/sys/users.vue'),
                meta: {
                    title: '账号管理',
                    auth: ['p_sysUsers']
                }
            },
            {
                path: 'roles',
                name: 'p_sysRoles',
                component: () => import('@/views/sys/roles.vue'),
                meta: {
                    title: '角色管理',
                    auth: ['p_sysRoles']
                }
            },
            {
                path: 'roles/add',
                name: 'p_sysRolesAdd',
                component: () => import('@/views/sys/rolesAdd.vue'),
                meta: {
                    title: '添加角色',
                    sidebar: false,
                    activeMenu: '/plat/sys/roles'
                }
            },
            {
                path: 'roles/edit/:id',
                name: 'p_sysRolesEdit',
                component: () => import('@/views/sys/rolesAdd.vue'),
                meta: {
                    title: '编辑角色',
                    sidebar: false,
                    activeMenu: '/plat/sys/roles'
                }
            },

            {
                path: 'users/add',
                name: 'p_sysUsersAdd',
                component: () => import('@/views/sys/usersAdd.vue'),
                meta: {
                    title: '添加账号',
                    sidebar: false,
                    activeMenu: '/plat/sys/users'
                }
            },
            {
                path: 'users/edit/:id',
                name: 'p_sysUsersEdit',
                component: () => import('@/views/sys/usersAdd.vue'),
                meta: {
                    title: '编辑账号',
                    sidebar: false,
                    activeMenu: '/plat/sys/users'
                }
            }
        ]
    }
];
